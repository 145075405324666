import * as React from "react";
import { graphql, Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import PageLayout from "../../components/page-layout/PageLayout";
import SEO from "../../components/SEO";

const ContactThanks = () => {
  const { t } = useTranslation();

  return (
    <PageLayout headerBg="gray-100" contactForm={false}>
      <SEO noindex={true} />
      <main className=" flex items-center justify-center flex-grow min-h-[80vh]">
        <div className="flex flex-col items-center">
          <h1 className="font-extrabold text-ol-gradient text-9xl">
            {t("join:joinForm.thanks.title")}
          </h1>

          <h6 className="text-2xl font-bold text-center text-gray-900 md:text-3xl max-w-3xl">
            {t("join:joinForm.thanks.subtitle")}
          </h6>

          <Link
            to="/"
            className="mt-8 font-bold text-gray-500 text-xl uppercase"
          >
            {t("join:joinForm.thanks.goHome")} →
          </Link>
        </div>
      </main>
    </PageLayout>
  );
};

export default ContactThanks;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
